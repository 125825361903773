export default [
  { iso: 'ae', name: 'The United Arab Emirates' },
  { iso: 'ar', name: 'Argentina' },
  { iso: 'at', name: 'Austria' },
  { iso: 'au', name: 'Autriche' },
  { iso: 'bg', name: 'Bulgaria' },
  { iso: 'be', name: 'Belgium' },
  { iso: 'br', name: 'Brazil' },
  { iso: 'ca', name: 'Canada' },
  { iso: 'ch', name: 'Switzerland' },
  { iso: 'cn', name: 'China' },
  { iso: 'co', name: 'Colombia' },
  { iso: 'cu', name: 'Cuba' },
  { iso: 'cz', name: 'Czechia' },
  { iso: 'de', name: 'Germany' },
  { iso: 'eg', name: 'Egypt' },
  { iso: 'fr', name: 'France' },
  { iso: 'gb', name: 'United Kingdom' },
  { iso: 'gr', name: 'Greece' },
  { iso: 'hk', name: 'Hong Kong' },
  { iso: 'hu', name: 'Hungary' },
  { iso: 'id', name: 'Indonesia' },
  { iso: 'ie', name: 'Ireland' },
  { iso: 'il', name: 'Israel' },
  { iso: 'in', name: 'India' },
  { iso: 'it', name: 'Italy' },
  { iso: 'jp', name: 'Japan' },
  { iso: 'kr', name: 'Korea' },
  { iso: 'lt', name: 'Lithuania' },
  { iso: 'lv', name: 'Latvia' },
  { iso: 'ma', name: 'Morocco' },
  { iso: 'mx', name: 'Mexico' },
  { iso: 'my', name: 'Malaysia' },
  { iso: 'ng', name: 'Nigeria' },
  { iso: 'nl', name: 'Netherlands' },
  { iso: 'no', name: 'Norway' },
  { iso: 'nz', name: 'New Zealand' },
  { iso: 'ph', name: 'Philippines' },
  { iso: 'pl', name: 'Poland' },
  { iso: 'pt', name: 'Portugal' },
  { iso: 'ro', name: 'Romania' },
  { iso: 'rs', name: 'Serbia' },
  { iso: 'ru', name: 'Russia' },
  { iso: 'sa', name: 'Saudi Arabia' },
  { iso: 'se', name: 'Sweden' },
  { iso: 'sg', name: 'Singapore' },
  { iso: 'si', name: 'Slovenia' },
  { iso: 'sk', name: 'Slovakia' },
  { iso: 'th', name: 'Thailand' },
  { iso: 'tr', name: 'Turkey' },
  { iso: 'tw', name: 'Taiwan' },
  { iso: 'ua', name: 'Ukraine' },
  { iso: 'us', name: 'United States of America' },
  { iso: 've', name: 'Venezuela' },
  { iso: 'za', name: 'South Africa' },
]